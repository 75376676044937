import * as Sentry from "@sentry/browser"
import sentryConfig from "../../config/sentryConfig.json"
import packageJson from "../../package.json"
import { isDemoEnv, isDevelopmentEnv, isStaging } from "../utils/globalVariables"

interface SentryExceptionExtraData {
  title: string
  data?: any
}

export type SentryUserInfo = {
  id: string
}

const shouldDisableSentry = isDevelopmentEnv() || isDemoEnv()

function initSentry() {
  if (shouldDisableSentry) return

  const config: Sentry.BrowserOptions = {
    dsn: sentryConfig.dsn,
    release: `${packageJson.version}@${GIT_COMMIT_HASH}`,
    environment: TARGET_ENV_TYPE,
    attachStacktrace: true,
    normalizeDepth: 20,
    integrations: (integrations) => {
      // integrations will be all default integrations
      return [
        ...integrations.filter((integration) => integration.name !== "GlobalHandlers"),
        Sentry.globalHandlersIntegration({
          // window.onerror captures errors from 3rd party code outside
          // our React App that don't show to users and we can't control
          onerror: false,
          onunhandledrejection: false,
        }),
        Sentry.replayIntegration({
          maskAllText: true,
          blockAllMedia: true,
          sessionSampleRate: 0.1,
          errorSampleRate: 1.0,
        }),
      ]
    },
    ignoreErrors: [
      // Ignore bengin error for ResizeObserver
      // Original issue: https://github.com/WICG/resize-observer/issues/38
      "ResizeObserver loop completed with undelivered notifications",
      // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
      "ResizeObserver loop limit exceeded",
      // We can't do anything about network blips.
      "A network error occurred",
      // Someone has a Solana wallet that throws errors
      "Cannot assign to read only property 'solana'",
      // Browser extension: https://github.com/getsentry/sentry-javascript/issues/8444#issuecomment-1618144868
      "Can't find variable: msDiscoverChatAvailable",
      // Nothing we can do about these errors
      "Request aborted",
    ],
    beforeSend(event) {
      // Ignore errors from Prerender.io
      if (
        window.navigator?.userAgent &&
        window.navigator.userAgent.indexOf("Prerender") !== -1
      )
        return null

      return event
    },
  }

  if (isStaging()) {
    config.debug = true
  }

  Sentry.init(config)
}

function sendSentryAnException(
  error: any,
  options?: {
    extra?: SentryExceptionExtraData
    onCapture?: (eventId: string) => void
    logOnlyWithUser?: boolean
  }
) {
  if (shouldDisableSentry) return

  Sentry.withScope((scope) => {
    const user = scope.getUser()

    if (!options?.logOnlyWithUser || (user && Object.keys(user).length)) {
      if (options?.extra) {
        scope.setExtras(options.extra as unknown as Record<string, unknown>)
      }

      scope.setExtra("error", error)
      const eventId = Sentry.captureException(error)

      if (options?.onCapture) {
        options.onCapture(eventId)
      }
    }
  })
}

function showReportDialog(eventId: string) {
  if (shouldDisableSentry) return

  Sentry.showReportDialog({ eventId })
}

function setUserContextForSentry(userInfo: SentryUserInfo | null) {
  if (shouldDisableSentry) return

  Sentry.configureScope((scope) => {
    scope.setUser(userInfo)
  })
}

export { initSentry, sendSentryAnException, setUserContextForSentry, showReportDialog }
